import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
// import HelpAndSupport from 'pages/helpAndSupport/SamplePage';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));
const Products = Loadable(lazy(() => import('pages/products/SamplePage')));
const SubscribedProducts = Loadable(lazy(() => import('pages/subscribed_products/SamplePage')));
const ProductDetail = Loadable(lazy(() => import('pages/productDetails/SamplePage')));
const SubscribedProductDetail = Loadable(lazy(() => import('pages/subscribedProductsDetails/SamplePage')));
const OtherProducts = Loadable(lazy(() => import('pages/other_products/SamplePage')));
const HelpAndSupport = Loadable(lazy(() => import('pages/helpAndSupport/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <SubscribedProducts />
    },
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'products',
      element: <Products />
    },
    {
      path: 'subscribed_products',
      element: <SubscribedProducts />
    },
    {
      path: 'subscribed_product_detail/:id',
      element: <SubscribedProductDetail />
    },
    {
      path: 'other_products',
      element: <OtherProducts />
    },
    {
      path: 'product_detail/:id',
      element: <ProductDetail />
    },
    {
      path: 'help_and_support',
      element: <HelpAndSupport />
    },

    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    }
  ]
};

export default MainRoutes;
